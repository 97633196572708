import { FC } from 'react'

import { VStack } from '../Stack'
import s from './AppLoader.module.scss'
import Logo from './fm-logo.svg?react'

export const AppLoader: FC = () => {

  return (
    <VStack
      align='center'
      gap='16'
      className={s.container}
    >
      <Logo />
    </VStack>
  )
}
