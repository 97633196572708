import { t } from '@lingui/macro'
import { ReactNode, useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { useTaskCount } from '@/entities/user'
import { to } from '@/shared/lib'
import { Counter } from '@/shared/ui'

import { type MenuItem as MenuItemType } from '../model/menuItems/menuItems'
import Build from '../ui/build-on.png'
import Friends from '../ui/friends-on.png'
import Labs from '../ui/labs-on.png'
import { MenuItem } from '../ui/MenuItem/MenuItem'
import Quests from '../ui/quests-on.png'
import s from './useMenuItems.module.scss'

export const useMenuItems = () => {
  const count = useTaskCount()

  //assigning location variable
  const location = useLocation()

  //destructuring pathname from location
  const { pathname } = location

  const menuItems = useMemo(() => ([
    {
      name: t`Quests`,
      path: to.quests(),
      imgPath: Quests,
    },
    {
      name: t`Labs`,
      path: to.game(),
      imgPath: Labs,
    },
    {
      name: t`Build menu`,
      path: to.upgrades(),
      imgPath: Build,
    },
    {
      name: t`Squad`,
      path: to.friends(),
      imgPath: Friends,
    },
  ]), [])

  const additionalRecord: Record<string, ReactNode> = useMemo(() => ({
    Quests: <Counter count={count ?? 0} className={s.counter} />,
    Build: null,
    Labs: null,
    Squad: null,
  }), [count])

  const renderLink = useCallback((menuItem: MenuItemType) => {
    const { imgPath, name, path, disabled } = menuItem

    return (
      <MenuItem
        key={name}
        imgPath={imgPath}
        name={name}
        path={path}
        disabled={disabled}
        pathname={pathname}
        additional={additionalRecord[name]}
      />
    )
  }, [pathname, additionalRecord])

  return menuItems.map(renderLink)
}
